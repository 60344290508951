// =============================
// Imports
// =============================

// External Dependencies
import PubNub from 'pubnub';

// =============================
// Business
// =============================

const pubNub = { client: null };

export default function getClient(locationProtocol, userId, authKey) {
  if (!pubNub.client) {
    pubNub.client = new PubNub({
      subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY,
      ssl: true,
      userId,
    });

    pubNub.client.setToken(authKey);
  }

  return pubNub.client;
}
